import { ImageComponent } from "@/components/ImageComponent";
import Link from "next/link";
import MetaComponent from "../components/Meta";

const Custom404 = () => {
  return (
    <>
      <MetaComponent
        title={`404: Page not found`}
        description={`This page could not be found`}
      />
        <div className="row align-items-center mycontainer mymain vh-100 p-0 m-0" style={{minHeight: "450px"}}>
          <div className="col-12 mx-auto">
            <div className="card denied-card col-12 col-sm-8 col-md-5 col-lg-4 m-auto py-4 px-3">
              <div className="card-body m-auto text-center px-0">
                <ImageComponent
                  src="https://designs.dmb2024.com/assets/images/icons/unlink.svg"
                  alt="lock"
                  className="my-3"
                  height={122}
                  width={122}
                />
                <h3 className="my-2">Error 404</h3>
                <h6 className="font-weight-normal mb-5">
                  This page could not be found.
                </h6>
                <Link href="/" legacyBehavior>
                  <span className="text-center col-12 mx-auto cursor">
                    <ImageComponent
                      src="https://designs.dmb2024.com/assets/images/icons/log-in.svg"
                      alt="login"
                      className="mx-2"
                    />
                    Go back to the dashboard
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default Custom404;
